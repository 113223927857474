import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';

import '@shoelace-style/shoelace/dist/components/button/button.js';

// custom components
import '../../components/link-button'

import { homeStyles } from './home-styles';
import { styles as sharedStyles } from './../../styles/shared-styles';

import { storeURL } from '../../constants';

@customElement('join-section')
class JoinSection extends LitElement {
  static styles = [homeStyles, sharedStyles];

  render() {
    return html`
      <section class="first">
        <div class="body">
          Join us on this transformative journey as we stand firm in our
          commitment to Empowering Lives, Breaking Barriers, and Building
          Futures. Together, we illuminate paths to a future where hope reigns
          and possibilities abound.
        </div>
        <link-button style="align-self: center;" href=${storeURL}>
            <sl-button slot="button-slot" class="button" variant="primary"
              >Buy Jewelry. Empower Lives</sl-button
            >
        </link-button>
      </section>
    `;
  }
}

export default JoinSection;
