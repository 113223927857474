import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';

// custom components
import '@shoelace-style/shoelace/dist/components/button/button.js';
import  '../../components/link-button';

// custom styles
import { homeStyles } from './home-styles';
import { styles as sharedStyles } from './../../styles/shared-styles';

import { storeURL } from '../../constants';

type Feature = {
  title: string;
  detail: string;
  id: number;
};

@customElement('whatwedo-section')
class WhatWeDoSection extends LitElement {

  @property() title = 'What We do';
  static featuresData: Feature[] = [
    {
      id: 1,
      title: 'Sustainable Funding for Social Impact',
      detail: `By operating an online store as a social enterprise, we can generate revenue through jewelry sales.
                     The profits earned from the store can be reinvested directly into the organization's social mission,
                     supporting programs and initiatives that empower women and children who have suffered from domestic
                     abuse and trauma. This sustainable funding model ensures that the organization can continue
                     its valuable work in the long term, making a lasting impact on the lives of those it serves.`,
    },
    {
      id: 2,
      title: 'Empower Gen Z Women',
      detail: `The online store’s focus on selling jewelry for Gen Z ladies not only generates revenue but also empowers and engages the target audience.
                     Gen Z women who purchase jewelry from the store become part of the solution,
                     knowing that their purchase supports a cause they care about deeply.
                     This sense of purpose and connection can enhance customer loyalty and attract new supporters.`,
    },
    {
      id: 3,
      title: 'Create Awareness and Advocacy',
      detail: `The online store provides a platform to showcase the charity organization’s mission and impact.
                     As customers browse the store and learn about the organization’s cause, it creates an opportunity for raising awareness about domestic abuse and trauma issues.
                     This increased awareness can lead to advocacy efforts and the promotion of positive social change.`,
    },
    {
      id: 4,
      title: 'Build a Community',
      detail: `The online store can foster a community of supporters and advocates.
                     Customers who purchase jewelry from the store may become more involved in the organization’s initiatives, participating in events, sharing their experiences, and spreading the word about the organization’s mission.
                    This sense of community can lead to a multiplier effect, expanding the organization’s reach and impact.`,
    },
  ];
  static styles = [
    sharedStyles,
    homeStyles,
    css`
      #prettycharm {
        height: 204px;
        display: grid;
        place-items: center;
        padding: 1rem;
      }

      .body a {
        text-decoration-color: var(--app-color-secondary);
        color: var(--app-color-secondary);

      }
      #prettycharm img {
        display: block;
        height: 8rem;
        width: 8rem;
      }

      .card {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        gap: 1rem;
        border-radius: 1rem;
        flex: 1 1 auto;
        filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
      }

      #feature {
        display: grid;
        gap: 2rem;
        /* container-type: inline-size; */
      }

      .card .title {
        font-family: var(--primary-font);
        font-weight: bold;
        font-size: 1.25rem;
        color: var(--primary-text);
      }

      .card .body {
        font-size: 1rem;
      }

      @container (min-width: 840px) {
        .card {
          gap: 2rem;
          padding: 2rem;
        }
        .card .title {
          font-size: 2rem;
        }

        .card .body {
          font-size: 1.4rem;
        }
      }

      @container (min-width: 840px) {
        #feature {
          gap: 3rem;
          grid-template-columns: 1fr 1fr;
          /* container-type: inline-size; */
        }

        #feature > .card:nth-child(1),
        #feature > .card:nth-child(4) {
          grid-column-start: 1;
          grid-column-end: span 2;
        }

        #feature > .card:nth-child(2),
        #feature > .card:nth-child(3) {
          grid-column: 1fr;
        }
      }

      @container (min-width: 1240px) {
        #feature {
          grid-template-columns: 1fr 1fr;
         }

        #feature > .card:nth-child(1),
        #feature > .card:nth-child(4) {
          grid-column-start: unset;
          grid-column-end: unset;
        }


        /* #feature {
          grid-template-columns: 1fr 1fr 1fr;
         }

        #feature > .card:nth-child(1) {
          grid-column-start: 1;
          grid-column-end: span 3;
        }

        #feature > .card:nth-child(4) {
          grid-column: unset;
        }

        #feature > .card:nth-child(4) {
          grid-column: 1fr;
        } */
      }
    `,
  ];

  getFeatureBg(id: number) {
    if (id === 1) {
      return {
        background: 'rgba(0, 128, 128, 0.54)',
      };
    }
    if (id === 2) {
      return {
        background: 'rgba(128, 0, 128, 0.54)',
      };
    }

    if (id === 3) {
      return {
        background: 'rgba(152, 251, 152, 0.54)',
      };
    }

    return {
      background: 'rgba(255, 215, 0, 0.54)',
    };
  }

  render() {
    const features = html`
      <section id="feature">
        ${WhatWeDoSection.featuresData.map(
          (featureItem) =>
            html`
              <article
                class="card"
                style=${styleMap(this.getFeatureBg(featureItem.id))}
              >
                <div class="title">${featureItem.title}</div>
                <div class="body">${featureItem.detail}</div>
              </article>
            `
        )}
      </section>
    `;
    return html`
      <section class="first">
        <div class="title">${this.title}</div>
        <div class="body">
          At the heart of our impact lies our vibrant social enterprise —
          <b>
            <a href="https://www.prettycharm.app" target="_blank">Prettycharm.</a></b> A captivating online store where we offer
          exquisite plated jewelry specially curated for Gen Z ladies. Every jewelry
          purchase serves a greater purpose, as the profits generated are
          reinvested into our programs, enabling us to extend a helping hand to
          those in need.
        </div>
        <div id="prettycharm">
          <img src="/assets/images/logo512.png" />
          <link-button href=${storeURL}>
          <sl-button slot="button-slot" class="button" variant="primary"
            >Buy Jewelry. Empower Lives</sl-button
          >
          </link-button>
        </div>
        <div class="body">
          Through our online store, we promote economic empowerment and social
          mobility and strengthen support systems for Women and children who
          survive domestic abuse. We provide Sustainable Funding for Social
          Impact, Empower Gen Z Women, create awareness and Advocacy, and build
          a community.
        </div>
        ${features}
      </section>
    `;
  }
}

export default WhatWeDoSection;

