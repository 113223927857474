import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';
//import { resolveRouterPath } from '../router';

import '@shoelace-style/shoelace/dist/components/button/button.js';
@customElement('app-header')
export class AppHeader extends LitElement {

  @property() title = 'Grace Divine';
  @property({ type: Boolean}) enableBack: boolean = false;

  static get styles() {
    return css`
      header {

        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--app-color-primary);
        color: white;
        height: 3.5rem;
        font-family: var(--app-title-font);
        width: 100%;
        position: -webkit-sticky;
        left: env(titlebar-area-x, 0);
        top: env(titlebar-area-y, 0);
        /* height: env(titlebar-area-height, 50px);
        width: env(titlebar-area-width, 100%); */
        -webkit-app-region: drag;
        border-bottom: 2px solid var(--app-color-secondary);
      }

      header h1 {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 20px;
        font-weight: bold;
        color: var(--app-color-secondary);
      }

      nav a {
        margin-left: 10px;
      }

      #back-button-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 12em;
      }

      @media(prefers-color-scheme: light) {
        header {
          color: black;
        }

        nav a {
          color: initial;
        }
      }

      @container (min-width: 840px) {
        header {
          height: 4.5rem;
          border-bottom: 4px solid var(--app-color-secondary);
        }

        header h1 {
          font-size: 1.8rem;

         }
      }
    `;
  }

  constructor() {
    super();
  }
  /*
  render() {
    return html`
      <header>

        <div id="back-button-block">
          ${this.enableBack ? html`<sl-button href="${resolveRouterPath()}">
            Back
          </sl-button>` : null}

          <h1>${this.title}</h1>
        </div>
      </header>
    `;
  }*/

  render() {
    return html`
      <header>
        <h1>${this.title}</h1>

      </header>
    `;
  }
}
