import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import { homeStyles } from './home-styles';

@customElement('hero-section')
export class HeroSection extends LitElement {
  @property() title = 'Empowering Lives, Breaking Barriers, Building Futures';
  @property()
  body = `At Grace Divine, we stand united in our unwavering commitment to empower lives,
                         break barriers, and build brighter futures for women and children affected by domestic
                         abuse and trauma. Central to our unique approach is our thriving social enterprise,
                         which serves as a driving force behind our passion for positive change and sustainable impact.`;
  static styles = [
    homeStyles,
    css`
      .hero {
        display: flex;
        flex-direction: column;
        background: var(--app-color-primary);
      }
    `,
  ];

  render() {
    return html`
      <section class="first">
        <div class="title">${this.title}</div>
        <div class="body">${this.body}</div>
      </section>
    `;
  }
}
