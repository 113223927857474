import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('link-button')
export class linkButton extends LitElement {
  // link to be opened when button is clicked
  @property() href = '';

  static styles = [
    css`
      :host {
        width: 100%;
      }

      @container (min-width: 840px) {
        :host {
          width: 40%;
        }
      }
    `,
  ];

  constructor() {
    super();
  }

  render() {
    return html`
      <a href=${this.href} target="_blank">
        <slot name="button-slot"></slot>
      </a>
    `;
  }
}

