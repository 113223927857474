import { css } from 'lit';

// these styles can be imported from any component
// for an example of how to use this, check /pages/about-about.ts
export const homeStyles = css`
  .title {
    font-family: var(--primary-font);
    font-weight: bold;
    font-style: italic;
    font-size: 1.25rem;
    color: var(--app-color-secondary);
  }

  .body {
    font-family: var(--primary-font);
    color: var(--primary-text);
    font-size: 1rem;
  }

  section.first {
    display: flex;
    flex-direction: column;
    background-color: var(--app-color-primary);
    padding: 2rem 1rem;
    gap: 1rem;
  }

  .home-layout {
    display: flex;
    flex-direction: column;
    // height: 100vh;
    gap: 2rem 0;
  }

  /**responsive styles */
  @media (min-width: 1000px) {
    sl-card {
      max-width: 70vw;
    }
  }

  @container (min-width: 840px) {
    .home-layout {
      gap: 4rem;
    }
    section.first {
      padding: 4rem 2rem;
      padding-block: 2rem;
      gap: 2rem;
      /* padding-inline: calc((100% - 840px) / 2); */
    }

    .title {
      font-size: 2rem;
    }

    .body {
      font-size: 1.4rem;
    }
  }
`;

