import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import { homeStyles } from './home-styles';

@customElement('whoweare-section')
export class WhoWeAreSection extends LitElement {
  @property() title = 'Who We Are';
  p1 = `At Grace Divine, we are a purpose-driven beacon of hope committed to empowering
           and uplifting the lives of women and children who have faced the harrowing realities of domestic abuse and trauma. Our unwavering dedication to their well-being and growth fuels our mission to break systemic barriers and build a future where individuals can rise above adversity.
           We believe in the transformative power of empowerment, and our work revolves around providing women and children with the support, resources, and opportunities they need to heal and thrive. Guided by the principle of dignity, we offer a dedicated helpline, emotional support, and crucial information, serving as a trusted sanctuary where they can find solace and strength.`;

  static styles = [homeStyles];

  render() {
    return html`
            <section class="first">
                <div class="title">
                  ${this.title}
                </div>
                <div class="body">
                  ${this.p1}
                </div>
                <div class="body">
                    Grace Divine is more than just a charitable entity;
                    it is a movement fueled by the collective strength of our supporters, volunteers, and beneficiaries. Together, we rewrite narratives, emboldening women and children to reclaim their lives and write their destinies.
                </div>
                </div>
            </section>
        `;
  }
}
