import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { resolveRouterPath } from '../router';

import { styles as sharedStyles } from '../styles/shared-styles';

@customElement('app-footer')
export class AppFooter extends LitElement {
  static styles = [
    sharedStyles,
    css`
      .title {
        font-family: var(--primary-font);
        font-weight: bold;

        font-size: 1.05rem;
        color: var(--primary-text);
      }

      #footer {
        background-color: var(--app-color-secondary-light);
        margin-top: 2rem;
      }

      #footer #top {
        padding: 2rem 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
      }

      .app-title {
        font-family: var(--app-title-font);
      }

      #footer #legal {
        background-color: var(--app-color-secondary);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3.5rem;
        gap: 0.8rem;
      }

      #footer .caption {
          font-size: 0.8rem;
      }

      .social-image {
        width: 1.5em;
        height: 1.5em;
        object-fit: cover;
      }

      #company-info,
      #contact-info,
      #connect-info {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        text-align: center;
        /* align-items: center; */
      }

      #contact-info {
        margin-bottom: 1rem;
      }

      #connect-info {
        row-gap: 1rem;
      }

      #connect-info > div:nth-child(2) {
        gap: 0.6rem;
        display: flex;
        justify-content: center;
      }

      /**responsize styles */
      @container (min-width: 840px) {

      #footer {
        margin-top: 4rem;
      }
      .title {
        font-size: 1.5rem;
      }


      #footer .caption {
          font-size: 1.1rem;
      }


        #footer #top {
          padding-inline: 2.5rem;

          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
        }

        #company-info,
        #contact-info,
        #connect-info {
          text-align: left;
          /* align-items: center; */
        }

        #connect-info {
          align-items: flex-start;
        }

        .social-image {
        width: 2rem;
        height: 2rem;

      }

      }
    `,
  ];

  render() {
    return html`
      <section id="footer">
        <section id="top">
          <div id="company-info">
            <div class="title app-title">Grace Divine</div>
            <div class="caption">
              Empowering Lives, Breaking Barriers,<br />
              Building Futures
            </div>
          </div>
          <div>
            <div id="contact-info">
              <div class="title">Contact Us</div>
              <a class="caption" href="mailto:email@gracedivine.co"
                >email@gracedivine.co</a
              >
              <a class="caption" href="tel:email@gracedivine.co"
                >+1 (443) 824-4914</a
              >
              <div class="caption">
                15 Astro Ct Parkville, Baltimore Maryland
              </div>
            </div>
            <!-- <div id="connect-info">
              <div class="title">Connect With Us</div>
              <div>
                <img class="social-image" src="/assets/images/twitter.png" />
                <img class="social-image" src="/assets/images/instagram.png" />
              </div>
            </div> -->
          </div>
        </section>
        <div id="legal">
          <div class="caption">
            &copy;&nbsp;${new Date().getFullYear()}&nbsp;Grace Divine Inc.
          </div>
          <a class="caption" href=${resolveRouterPath('legal')}
            >Privacy Policy</a
          >
        </div>
      </section>
    `;
  }
}

