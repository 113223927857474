import { css } from 'lit';

// these styles can be imported from any component
// for an example of how to use this, check /pages/about-about.ts
export const styles = css`
  .caption {
    font-family: var(--secondary-font);
    font-weight: medium;
    font-size: 0.8em;
    color: var(--primary-text);
  }

  .button::part(base) {
    background-color: var(--app-color-secondary);
    text-transform: uppercase;
    color: white;
    font-weight: medium;
    border-color: transparent;
    place-items: center;
  }

  .button {
    width: 100%;
    height: 2.2rem;
  }

  .divider {
    display: block;
    height: 2px;
    width: 100%;
    background-color: red; //var(--app-color-secondary);
  }

  main {
    container-type: inline-size;
  }

  @container (min-width: 840px) {
    .button::part(base) {
    }

    .button {
      /* width: 40rem; */
      height: 3rem;
    }
  }
`;

