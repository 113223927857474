import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js'

import './pages/app-home/app-home';
import './components/header';
import './components/footer';
import './styles/global.css';
import { router } from './router';

@customElement('app-index')
export class AppIndex extends LitElement {
  static get styles() {
    return css`
      #index-container {
        container-type: inline-size;
      }

      .hideFooter {
        display: none;
      }

      main {
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 16px;
      }

      app-website-simple::part(header-hero) {
        --header-background: #fff;
      }
    `;
  }

  constructor() {
    super();
  }

  @property({type: Object})
  classes = {hideFooter: false}

  firstUpdated() {
     router.addEventListener('route-changed', ({context}: any) => {
      console.log('route changed')
      console.dir(context)
      const pathname = context?.url.pathname
      const allowedPaths = ['/legal', '/about']
      if (pathname?.length > 0 && !allowedPaths.includes(pathname) ) {
            this.classes.hideFooter = true
      }
      if ('startViewTransition' in document) {
        return (document as any).startViewTransition(() => {
          this.requestUpdate();
        });
      } else {
        this.requestUpdate();
      }
    });
  }

  render() {
    // router config can be round in src/router.ts
    return html`
      <div id="index-container">
        ${router.render()}
        <app-footer class=${classMap(this.classes)} style="margin-top: 1.5rem"></app-footer>
      </div>
    `
  }
}

